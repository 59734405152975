import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { Router } from '@angular/router';
import { ApiConfig } from './../config/apiConfig';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string , password: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(ApiConfig.API_LOGIN, {username, password}, {headers})
    .pipe(map(response => {
      if (response.statusCode === 200) {
        const user = response.data;
          if (user && user.accessToken) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
      }
      return response;
    }));
  }

  logout() {
    return this.http.post<any>(ApiConfig.API_LOGOUT, '');
  }

  handleAfterLogout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('agencies');
    localStorage.removeItem('makers');
    localStorage.removeItem('searchConfition');
    localStorage.removeItem('makerListAfterSearch');
    localStorage.removeItem('companyListAfterSearch');
    localStorage.removeItem('divisionListAfterSearch');
    localStorage.removeItem('offficeListAfterSearch');
    localStorage.removeItem('agencyListAfterSearch');
    localStorage.removeItem('searchConditionProperty');
    localStorage.removeItem('companyHelp');
    this.router.navigate(['login']);
    this.currentUserSubject.next(null);
  }

}
