import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  @ViewChild('myModal') public dialog: ModalDirective;
  @Input() content: string = '';
  @Input() type: string = 'dark';
  @Output() confirmEvent = new EventEmitter();
  @Input() id: string;
  @Input() actionButton: string = '';
  @Input() closeButton: string = '';
  constructor(
  ) { }
  ngOnInit(): void {
  }

  hideModal() {
    document.body.classList.remove('modal-open');
    const modalContainer = document.querySelector('modal-container');
    if (modalContainer !== null) {
      modalContainer.parentNode.removeChild(modalContainer);
    }
  }

  show(content?: string, type?: string, actionButton?: string, closeButton?: string, isCreateOrder?: boolean) {
    if (content) { this.content = content; }
    if (actionButton) { this.actionButton = actionButton; }
    if (closeButton) { this.closeButton = closeButton; }
    if (type) {
      if (type === 'error') {
        this.type = 'danger';
      } else if (type === 'warning') {
        this.type = 'warning';
      } else if (type === 'success') {
        this.type = type;
      } else {
        this.type = type;
      }
    }
    this.dialog.show();
  }

  hide() {
    this.content = '';
    this.type = 'dark';
    this.actionButton = '';
    this.closeButton = '';
    this.dialog.hide();
    document.body.classList.remove('modal-open');
    const modalContainer = document.querySelector('modal-container');
    if (modalContainer !== null) {
      modalContainer.parentNode.removeChild(modalContainer);
    }
  }

  setId(id?: string) {
    this.id = id;
  }

  action() {
    this.hide();
    this.confirmEvent.emit({ id: this.id, action: 'OK' });
  }

  close() {
    this.hide();
    this.confirmEvent.emit({ id: this.id, action: 'CLOSE' });
  }
}
