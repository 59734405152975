import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
// import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-confirm-dialog-error',
  templateUrl: './confirm-dialog-error.component.html',
  styleUrls: ['./confirm-dialog-error.component.css']
})
export class ConfirmDialogErrorComponent implements OnInit {
  subject: Subject<boolean>;
  message: string;
  closeBtnName: string;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    // this.authService.logout();
  }
  action(value: boolean) {
    this.bsModalRef.hide();
    // this.authService.logout();
  }
}
