<div class="container-fluid">
    <div class="row no-gutter bg-image">
        <div class="col-md-3 justify-content-center align-items-center">
            <!-- <p  *ngIf="false" class="logo">
                <a href="#">
                    <img src="../../../../assets/images/logo.svg" alt="" />
                </a>
            </p> -->
        </div>
        <div class="col-8 col-md-6">
            <div class="login d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-9 col-md-8 mx-auto">
                            <div class="icon-header">
                                <i class="fa fa-user-circle-o"></i>
                            </div>
                            <form [formGroup]="formLogin" (ngSubmit)="handleLogin()" autocomplete="off">
                                <div class="form-label-group">
                                    <input #inputUsername maxlength="255" (focusout)="autoTrim()" type="text" id="inputEmail" class="form-control" placeholder="Email address" formControlName="username" />
                                    <label for="inputEmail"> <i class="fa fa-user icon-form" aria-hidden="true"></i> ユーザーID</label>
                                    <div *ngIf="ct.username.errors && (submitted || ct.username.dirty || ct.username.touched)" class="required-text">
                                        <div *ngIf="ct.username.errors.required">
                                            ユーザーIDを入力してください。
                                        </div>
                                        <div *ngIf="ct.username.errors.pattern">
                                            ユーザーIDは半角英字（a～z、A～Z）、半角数字（0～9）、ハイフン（-） 、アンダースコア（_）で入力してください。
                                        </div>
                                    </div>
                                </div>
                                <div class="form-label-group">
                                    <input type="password" (focusout)="autoTrim()" id="inputPassword" class="form-control" placeholder="Password" formControlName="password" />
                                    <label for="inputPassword"><i class="fa fa-key icon-form" aria-hidden="true"></i> パスワード</label>
                                    <div *ngIf="ct.password.errors && (submitted || ct.password.dirty || ct.password.touched)" class="required-text">
                                        <div *ngIf="ct.password.errors.required">
                                            パスワードを入力してください。
                                        </div>
                                        <div *ngIf="ct.password.errors.minlength || ct.password.errors.maxlength">
                                            パスワードは6文字以上32文字以下で入力してください。
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-lg btn-block btn-border-radius btn-danger text-uppercase font-weight-bold mb-2" type="submit">
                                    ログイン
                                </button>
                                <!-- <div> -->
                                <p class="text-center mt-4">ログインにお困りの方は<a [routerLink]="['/forgot-password']" href="">こちら</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Show dialog-->
<app-dialog></app-dialog>
<!--Show spinner-->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#20a8d8" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white">読み込み中</p>
</ngx-spinner>
