import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ForgotPasswordService } from './forgot-password.service';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import * as Constanst from '../../../_utils/contanst';

@Component({
  selector: 'app-reset-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild(DialogComponent, { static: false }) dialog: DialogComponent;
  formForgotPassword: FormGroup;
  submitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private forgotPasswordService: ForgotPasswordService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.declareFormEditInforUser();
  }

  declareFormEditInforUser() {
    this.formForgotPassword = this.fb.group({
      user_name: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9０-９]*$')] ],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9０-９_+&*-]+(?:\.[a-zA-Z0-9０-９_+&*-]+)*@(?:[a-zA-Z0-9０-９-]+\.)+[a-zA-Z]{2,7}$/)]],
    });
  }

  get ct() {
    return this.formForgotPassword.controls;
  }

  handleForgotPassword() {
    this.submitted = true;
    if (this.formForgotPassword.invalid) {
      return false;
    } else {
      this.submitted = false;
      this.spinner.show();
      this.forgotPasswordService.forgotPassword(this.formForgotPassword.value).subscribe(
        (res) => {
          this.dialog.setId('success');
          this.dialog.show('入力されたメールアドレスに新しいパスワードをお送りしましたので、ご確認ください。', 'success');
          this.spinner.hide();
        },
        (rej) => {
          this.spinner.hide();
          this.dialog.show('ユーザーIDまたはメールアドレスが間違っています。', 'error');
        }
      );
    }
  }

  handleDialogConfirm({ id, action }) {
    if ( action === 'CLOSE') {
      if (id === 'success') {
        this.router.navigate(['/login']);
      }
    }
  }

  handleBackLogin() {
    this.router.navigate(['/login']);
  }

  autoTrim() {
    this.formForgotPassword.patchValue({
      user_name: this.ct.user_name.value.trim(),
      email: this.ct.email.value.trim(),
    });
  }

}
