import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './../../../_services/authentication.service';
import { Globals } from './../../../_utils/globals';
import { DialogComponent } from './../../../components/dialog/dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @ViewChild(DialogComponent, { static: false }) dialog: DialogComponent;
  @ViewChild('inputUsername') inputUsername: ElementRef;
  formLogin: FormGroup;
  submitted: boolean = false;
  globals = Globals;
  goToUrl: any = '';
  returnUrl: string;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.declareFormLogin();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.goToUrl = params.returnUrl;
    });
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterViewInit() {
    setTimeout(() => this.inputUsername.nativeElement.focus());
  }

  declareFormLogin() {
    this.formLogin = this.fb.group({
      username: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9０-９]*$')
        ],
      ],
      password: ['',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(33),
        ]
      ],
    });
  }

  // Begin Get controls Form
  get ct() {
    return this.formLogin.controls;
  }
  // End Get controls Form

  autoTrim() {
    this.formLogin.patchValue({
      username: this.ct.username.value.trim(),
      password: this.ct.password.value.trim(),
    });
  }

  handleLogin() {
    this.submitted = true;
    if (this.formLogin.invalid) {
      return false;
    } else {
      this.submitted = false;
      this.spinner.show();
      this.authenticationService
        .login(this.ct.username.value, this.ct.password.value).subscribe(
          (res: any) => {
            if (res.statusCode === 200) {
              localStorage.setItem('companyHelp', JSON.stringify(res.data.companyHelp))
              let lenghtKey = this.ct.password.value.length;
              lenghtKey += 5;
              localStorage.setItem(
                'lenghtKey',
                JSON.stringify(lenghtKey)
              );
              this.spinner.hide();
              if(res.data.isChangePass == '1') {
                this.router.navigate(['/user-settings']);
              } else {
                this.router.navigate([this.goToUrl || 'top-page']);
              }
            }
          },
          (err) => {
            this.spinner.hide();
            this.dialog.show('ユーザーIDまたはパスワードが間違っています。', 'error');
          }
        );
    }
  }
}
