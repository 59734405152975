<app-header [navbarBrandRouterLink]="['/top-page']" [fixed]="true"
  [navbarBrandFull]="{class: 'logo-img', src: urlLogo, width: (this.useRole == this.ROLE.MBS || this.useRole == this.ROLE.MAKER) ? '287' : '', height: 30, alt: 'Mitsuwa Logo'}"
  [navbarBrandMinimized]="{class: 'logo-img', src: urlLogo, width: (this.useRole == this.ROLE.MBS || this.useRole == this.ROLE.MAKER) ? 287 : '', height: 30, alt: 'Mitsuwa Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false">
  <div class="ml-md-auto">
    <h2 class="mb-0 font-weight-bold font-size-tittle-service">
      入居者様向け商品注文サービス
    </h2>
  </div>
  <ul class="nav navbar-nav ml-md-auto">
    <li class="nav-item px-3">
      <div *ngIf="this.useRole != this.ROLE.MBS && this.useRole != this.ROLE.MAKER" dropdown style="font-size: 25px; cursor: pointer;">
        <a class="text-dark" href id="nav-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown"><i
            class="fa fa-question-circle-o" aria-hidden="true"></i></a>
        <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu custom-position" role="menu"
          aria-labelledby="basic-link">
          <li>
            <a *ngFor="let item of companyHelp" class="dropdown-item" (click)="downloadPdf(item.url)">{{item.label}}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item px-3 d-flex mt-2 pt-2">
      <i style="font-size: 20px;" class="fa fa-user-circle-o" aria-hidden="true"></i>
      <p class="ml-2">{{currentUser.username}}</p>
    </li>
    <li class="nav-item px-3" style="font-size: 25px; cursor: pointer;">
      <a class="nav-link" (click)="logOut()"><i class="fa fa-sign-out" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="listSideBar" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid mt-2 overflow-hidden">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->
  </main>
</div>
<app-footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-9 d-flex justify-content-between">
        <div>
          <span style="color: #606060;">Copyright &copy; 2020 MITSUWA Business Solutions Co., Ltd. All Rights
            Reserved.</span>
        </div>
      </div>
    </div>
  </div>
</app-footer>
<app-dialog (confirmEvent)="handleLogOut($event)"></app-dialog>
