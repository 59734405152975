import { Injectable } from '@angular/core';
import { User } from '../_models/user';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CheckRoleService {
  currentUser: User;
  role: string = '';

  constructor(
    private authenticationService: AuthenticationService
    ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  checkRole() {
    return this.currentUser.roleName;
  }
}
