<div (onHide)="hideModal()" bsModal [config]="{backdrop: 'static',  keyboard: false}" #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content f-modal-alert p-0">
            <div *ngIf="this.type.indexOf('warning') !== -1">
                <div class="f-modal-icon f-modal-warning scaleWarning">
                    <span class="f-modal-body pulseWarningIns"></span>
                    <span class="f-modal-dot pulseWarningIns"></span>
                </div>
            </div>
            <div *ngIf="this.type.indexOf('success') !== -1 || this.type.indexOf('order_inputing') !== -1">
                <div class="f-modal-icon f-modal-success animate">
                    <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                    <span class="f-modal-line f-modal-long animateSuccessLong"></span>
                    <div class="f-modal-placeholder"></div>
                    <div class="f-modal-fix"></div>
                </div>
            </div>
            <div *ngIf="this.type.indexOf('danger') !== -1">
                <div class="f-modal-icon f-modal-error animate">
                    <span class="f-modal-x-mark">
                    <span class="f-modal-line f-modal-left animateXLeft"></span>
                    <span class="f-modal-line f-modal-right animateXRight"></span>
                    </span>
                    <div class="f-modal-placeholder"></div>
                    <div class="f-modal-fix"></div>
                </div>
            </div>
            <div class="modal-body text-center pt-3">
                <p style="white-space: pre-wrap" class="notifi-text">{{content}}</p>
                <p *ngIf="this.type == 'order_inputing'" style="white-space: pre-wrap" class="notifi-text">同じ物件に未提出の注文があります。</p>
                <p *ngIf="this.type == 'order_inputing'" style="white-space: pre-wrap" class="notifi-text">内容を確認しますか？</p>

            </div>
            <div class="modal-footer pb-4">
                <button *ngIf="actionButton && type === 'default'" type="button" class="btn width-btn btn-pill" [ngClass]="this.type=='default'?'btn-danger':'btn-warning'" (click)="action()">{{actionButton}}</button>
                <button *ngIf="actionButton && type === 'primary'" type="button" class="btn width-btn btn-pill 'btn-primary" (click)="action()">{{actionButton}}</button>
                <button *ngIf="actionButton && type === 'order_inputing'" type="button" class="btn width-btn btn-pill btn-primary" (click)="action()">{{actionButton}}</button>
                <button type="button" class="btn btn-secondary width-btn btn-pill" (click)="close()">{{closeButton|| '閉じる'}}</button>
            </div>
        </div>
    </div>
</div>
