<div class="container-fluid">
    <div class="row no-gutter bg-image">
        <div class="col-md-3 justify-content-center align-items-center">
        </div>
        <div class="col-8 col-md-6">
            <div class="login d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-9 col-md-8 mx-auto">
                            <div class="icon-header">
                                <i class="fa fa-unlock"></i>
                            </div>
                            <form [formGroup]="formForgotPassword" (submit)="handleForgotPassword()" autocomplete="off">
                                <div>
                                    <div>
                                        <p class="mb-2 font-weight-bold">パスワードが不明な場合</p>
                                        <p class="mb-2 py-2">以下に、ユーザーIDと、登録済のメールアドレスを入力してください<br> 新しいパスワードを発行し、メールアドレス宛に通知します。
                                        </p>
                                    </div>
                                    <div class="content-padding-left">
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-label-group">
                                    <input #inputUsername type="text" maxlength="255" (focusout)="autoTrim()" formControlName="user_name" id="inputEmail" class="form-control" placeholder="User name" />
                                    <label for="inputEmail"> <i class="fa fa-user icon-form" aria-hidden="true"></i> ユーザーID</label>
                                    <div *ngIf="ct.user_name.errors && (submitted || ct.user_name.dirty || ct.user_name.touched)" class="required-text">
                                        <div *ngIf="ct.user_name.errors.required">
                                            ユーザーIDを入力してください。
                                        </div>
                                        <div *ngIf="ct.user_name.errors.pattern">
                                            ユーザーIDは半角英字（a～z、A～Z）、半角数字（0～9）、ハイフン（-） 、アンダースコア（_）で入力してください。
                                        </div>
                                    </div>
                                </div>
                                <div class="form-label-group">
                                    <input type="email" formControlName="email" (focusout)="autoTrim()" id="inputPassword" class="form-control" placeholder="Email address" />
                                    <label for="inputPassword"><i class="fa fa-envelope icon-form" aria-hidden="true"></i> メールアドレス</label>
                                    <div *ngIf="ct.email.errors && (submitted || ct.email.dirty || ct.email.touched)" class="required-text">
                                        <div *ngIf="ct.email.errors.required">
                                            メールアドレスを入力してください。
                                        </div>
                                        <div *ngIf="ct.email.errors.pattern">
                                            メールアドレスの形式が不正です。
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex col-12 pl-0 pr-0">
                                    <div class="col-4 pl-0">
                                        <button (click)="handleBackLogin()" class="btn btn-lg btn-block btn-border-radius btn-secondary text-uppercase font-weight-bold mb-2">戻る</button>
                                    </div>
                                    <button class="col-8 btn btn-lg btn-block btn-border-radius btn-danger text-uppercase font-weight-bold mb-2 mt-0" type="submit">
                                        パスワード再発行依頼
                                    </button>
                                </div>
                                <div>
                                    <div class="mt-4">
                                        <p class="mb-2 font-weight-bold">ユーザーIDまたはメールアドレスが不明な場合、その他お困りの場合</p>
                                        <p class="mb-2 py-2">以下、お問合せ窓口までご連絡ください。</p>
                                        <p class="mb-2 content-padding-left">【お問合せ窓口】</p>
                                        <p class="mb-2">株式会社三ッ輪ビジネスソリューションズ</p>
                                        <p class="mb-2">0120-328-114 年中無休 9:00～18:00</p>
                                    </div>
                                    <!-- <div class="content-padding-left">
                                        <div>
                                            <p class="mb-2 py-2">以下、お問合せ窓口までご連絡ください。</p>
                                            <p class="mb-2 pl-3">【お問合せ窓口】</p>
                                            <p class="mb-2 pl-4">株式会社三ッ輪ビジネスソリューションズ</p>
                                            <p class="mb-2 pl-4">0120-328-114 年中無休 9:00～18:00</p>
                                        </div>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Show dialog-->
<app-dialog (confirmEvent)="handleDialogConfirm($event)"></app-dialog>
<!--Show spinner-->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#20a8d8" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white">読み込み中</p>
</ngx-spinner>