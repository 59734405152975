import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor() { }

  handleErrorMassage(message) {
    switch (message) {
      case 'Your user ID or password was incorrect !':
        return 'ユーザーIDまたはパスワードが間違っています。';
        break;
      case 'Get detail order fail !':
        return 'Hello2';
        break;
    }
  }
}
