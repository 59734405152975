
export const ROLE = {
    MBS: 'MBS',
    COMPANY: 'COMPANY',
    DIVISION: 'DIVISION',
    OFFICE: 'OFFICE',
    AGENCY: 'AGENCY',
    MAKER: 'MAKER',
};

export const ORDER_STATUS = {
    INPUTING: {
        CODE: '00',
        NAME: '注文入力中',
    },
    ORDERED: {
        CODE: '01',
        NAME: '注文提出済',
    },
    CONFIRMED: {
        CODE: '02',
        NAME: '注文確定',
    },
    DELETED: {
        CODE: '03',
        NAME: '削除済',
    },
    SHIPPED: {
        CODE: '04',
        NAME: '出荷済み',
    },
};

export const FORMATTER = {
    DATE: 'yyyy/MM/dd',
}

export const IS_SEKISUI = '1';
export const IS_CURTAIN = '1';

export const OPTIONS_TYPE = {
    REQUIRED: '1',
    NOT_REQUIRED: '0',
};

export const ORDER_TYPE = {
    EXTERNAL: '0',
    INTERNAL: '1',
};

export const DELIVERY_TYPE = {
    COMPANY: '0',
    PROPERTY: '1',
};

export const VALUE = {
    STRING_EMPTY: '',
    DATE_EMPTY: '----/--/--',
}

export const MODEL = {
    TYPE: {
        DEFAULT: 'default',
        SUCCESS: 'success',
        ERROR: 'error',
        WARNING: 'warning',
        ORDER_INPUTING: 'order_inputing'
    },
    ACTION: {
        OK: 'OK',
        CANCEL: 'cancel',
    },
}
