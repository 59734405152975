<div class="modal-body">
    {{message}}
</div>
<div class="modal-footer">
    <button class="btn btn-primary right btn-sm" (click)="action(true)">{{closeBtnName}}</button>
    <!-- <button class="btn btn-danger btn-sm" (click)="action(false)">キャンセル</button> -->
</div>



<!-- <div class="modal-body text-center pt-3">
    <div class="f-modal-icon f-modal-error animate">
        <span class="f-modal-x-mark">
                        <span class="f-modal-line f-modal-left animateXLeft"></span>
        <span class="f-modal-line f-modal-right animateXRight"></span>
        </span>
        <div class="f-modal-placeholder"></div>
        <div class="f-modal-fix"></div>
    </div>
    <p style="white-space: pre-wrap" class="notifi-text">12312312</p>
</div>
<div class="modal-footer pb-4">
    <button class="btn btn-primary right btn-sm" (click)="action(true)">OK</button>
    <button class="btn btn-danger btn-sm" (click)="action(false)">キャンセル</button>
    <button type="button" class="btn btn-secondary width-btn btn-pill" (click)="close()">{{closeButton|| 'Close'}}</button>
</div> -->