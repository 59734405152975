export const environment = {
  production: true,
  /* STG domain **/
  apiUrl: 'https://stg-order.mbs-web.jp/api/',
  helpUrl: 'https://mitsuwa-stg.s3-ap-northeast-1.amazonaws.com/master/特約店マニュアル.pdf',

  /* PROD domain **/
  // apiUrl: 'https://order.mbs-web.jp/api/',
  // helpUrl: 'https://mitsuwa-production.s3.ap-northeast-1.amazonaws.com/master/%E7%89%B9%E7%B4%84%E5%BA%97%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf',
  curtainMountList: 'https://mitsuwa-production.s3.ap-northeast-1.amazonaws.com/master/%E3%82%AB%E3%83%BC%E3%83%86%E3%83%B3%E5%8F%B0%E7%B4%99%E4%B8%80%E8%A6%A7.pdf',
  curtainPaymentForm: 'https://mitsuwa-production.s3.ap-northeast-1.amazonaws.com/master/%E3%80%90FAX%E3%80%91%E3%82%AB%E3%83%BC%E3%83%86%E3%83%B3%E6%9C%89%E5%84%9F%E3%82%B5%E3%83%B3%E3%83%97%E3%83%AB%E6%B3%A8%E6%96%87%E6%9B%B8.xlsx',
  forResidents: 'https://mitsuwa-production.s3.ap-northeast-1.amazonaws.com/master/A4%E3%83%AA%E3%83%BC%E3%83%95%E3%83%AC%E3%83%83%E3%83%88%EF%BC%88%E5%85%A5%E5%B1%85%E8%80%85%E7%94%A8%EF%BC%89.pdf',
  forDealers: 'https://mitsuwa-production.s3.ap-northeast-1.amazonaws.com/master/A4%E3%83%AA%E3%83%BC%E3%83%95%E3%83%AC%E3%83%83%E3%83%88%EF%BC%88%E7%89%B9%E7%B4%84%E5%BA%97%E3%83%BB%E5%8D%94%E5%8A%9B%E5%BA%97%E6%A7%98%E6%89%8B%E5%85%83%E7%A2%BA%E8%AA%8D%E7%94%A8%EF%BC%89.pdf',
};
