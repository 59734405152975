import { environment } from '../../environments/environment';


export class ApiConfig {
    public static API_LOGIN = environment.apiUrl.concat('login');
    public static API_LOGOUT = environment.apiUrl.concat('logout');
    public static API_SEARCH_ORDER = environment.apiUrl.concat('order');
    public static API_GET_COMPANYS = environment.apiUrl.concat('master/company');
    public static API_GET_AGENCY_STATUS = environment.apiUrl.concat('master');
    public static API_SEARCH_PROPERTY = environment.apiUrl.concat('property');
    public static API_DETAIL_ORDER = environment.apiUrl.concat('order');
    public static API_GET_INFO = environment.apiUrl.concat('user-info');
    public static API_CREATE_ORDER = environment.apiUrl.concat('order');
    public static API_COLOR_LACE_PRICE_LIST = environment.apiUrl.concat('master/product');
    public static API_PRODUCT_LIST = (id: string) => environment.apiUrl.concat(`master/category/${id}/product`);
    public static API_EPORT_ORDER_PDF = environment.apiUrl.concat('order/pdf');
    public static API_EPORT_ORDER_CSV = environment.apiUrl.concat('order/csv');
    public static API_ALL_NOTIFICATION = (page: string, limit: string) => environment.apiUrl.concat(`notification?page=${page}&limit=${limit}`);
    public static API_GET_ZIPCODE = environment.apiUrl.concat('master/get-zipcode');
    public static API_GET_CSV_PERMISSION = environment.apiUrl.concat('order/csv/template');
    public static API_FORGOT_PASSWORD = environment.apiUrl.concat('forgot-password');
    public static API_USER_SETTINGS = environment.apiUrl.concat('user-settings');
    public static API_SHIP_INFO = environment.apiUrl.concat('order/ship-info');
    public static API_CHECK_EXPECTED_SHIP_DATE = environment.apiUrl.concat('master/check-expected-ship-date');
    public static API_EXPECTED_SHIP_DATE = environment.apiUrl.concat('order/expected-ship-date');
    public static API_GET_DETAIL_PROPERTY = environment.apiUrl.concat('property');
    public static API_REGISTER_DETAIL_PROPERTY = environment.apiUrl.concat('property/memo');
    public static API_GET_CATEGORY = environment.apiUrl.concat('master/product-category');
    public static API_GET_PRODUCT_OPTION = environment.apiUrl.concat('master/product-option');
    public static API_GET_PRICE_PRODUCT_OTHER = environment.apiUrl.concat('master/price-product-other');
    public static API_VALID_ESTIMATE_DELIVERY_DATA = environment.apiUrl.concat('order/validate/estimate-delivery-date');
}
