import { DialogComponent } from './../components/dialog/dialog.component';
import { Injectable, ViewChild } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogErrorComponent } from '../components/confirm-dialog-error/confirm-dialog-error.component';
import { ErrorMessageService } from './../_services/error-message.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    @ViewChild(DialogComponent, { static: false }) dialog: DialogComponent;
    bsModalRef: BsModalRef;
    checkFirstErrors: boolean = false;
    constructor(
        private authenticationService: AuthenticationService,
        private modalService: BsModalService,
        private errorMessageService: ErrorMessageService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(evt => {
            if (evt instanceof HttpResponse) {
                if (evt.body.statusCode === 200 || evt.body.statusCode === 201) {
                    this.checkFirstErrors = false;
                }
                if (evt.body.statusCode === 401 || evt.body.statusCode === 500) {
                    this.authenticationService.logout();
                    this.authenticationService.handleAfterLogout();
                }
                return;
            }
            return;
        }), catchError(err => {
            if (err.error.statusCode === 401 || err.error.statusCode === 403) {
                if (!this.checkFirstErrors) {
                    this.bsModalRef = this.modalService.show(ConfirmDialogErrorComponent, { 'class': 'modal-dialog-primary' });
                    this.bsModalRef.content.message = 'セッションの有効期限が切れました。';
                    this.bsModalRef.content.closeBtnName = '閉じる';
                    this.authenticationService.logout();
                    this.authenticationService.handleAfterLogout();
                }
                this.checkFirstErrors = true;
            }
            const error = err.error || err.statusText;
            return throwError(error);
        }));
    }
}
