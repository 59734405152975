import { AuthenticationService } from './../_services/authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.accessToken) {
      if (request.url.indexOf('import') !== -1) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        });
      } else {
        if (request.url.indexOf('auth/login') !== -1) {
          request = request.clone({
            setHeaders: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          });
        } else {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${currentUser.accessToken}`,
              'Content-Type': 'application/json; charset=utf-8',
            },
          });
        }
      }
    }

    return next.handle(request);
  }
}
